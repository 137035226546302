// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//live api's 
export const environment = {
  production: true,
  pages: "https://leverageedu.com/services/pages/founders",
  platform: "https://leverageedu.com/services/platform/",
  student: "https://api.leverageedu.com/services/ssplp/",
  leverageApp: "https://api.leverageedu.com/services/community/",
  account: "https://leverageedu.com/services/account/",
  events: "https://leverageedu.com/account/",
  client: "https://cms.leverageedu.com/api/v1/",
  tools:  "https://leverageedu.com/services/platform/tools/",
  marketplace:  "https://leverageedu.com/services/marketplace/",
  PortalLogin: "https://api.leverageedu.com/services/ssplp/",
  forUniPagePreview: "https://leverageedu.com/universities",
  leverageSite: " https://leverageedu.com/services/integrations/",
  offerHolderParentCommunityId : "15",
    referralManagment : "https://api.leverageedu.com/services/ssplp/"


};

// pat  api's 
// export const environment = {
//   production: false,
//   pages: "https://pat.leverageedu.com/services/pages/founders",
//   platform:
//     "https://apiuat.leverageedu.com/ip/"
//  //"http://localhost:4001/"
//   ,
//   student:
//    "https://apiuat.leverageedu.com/portal/",
// // "http://localhost:4007/",
// leverageApp:
// "https://apiuat.leverageedu.com/community/"
// //  "http://localhost:8010/"
// ,
//   account:
//   "https://apiuat.leverageedu.com/account/"
//   //  "http://localhost:4009/"
//   ,
//   events: "https://pat.leverageedu.com/account/",
//   client: 
//       "https://cmsuat.leverageedu.com/api/v1/"
//     //"http://localhost:4001/"
//   ,

//   tools:
//     "https://apiuat.leverageedu.com/ip/tools/"
//   // "http://localhost:4001/tools/"
//   ,
//   PortalLogin:
//     "https://apiuat.leverageedu.com/portal/"
//   // "http://localhost:4001/"
//   ,
//   marketplace:
//     "https://apiuat.leverageedu.com/portal/",
//   // "http://localhost:4002/",
//     leverageSite: " https://leverageedu.com/services/integrations/",


//   forUniPagePreview: "https://ipnew.leverageedu.com/universities",
//   offerHolderParentCommunityId : "15",
//   referralManagment :     "https://apiuat.leverageedu.com/portal/",


// };


export const s3BucketUrl = "https://s3.ap-south-1.amazonaws.com/leverageedu/";

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
